<template>
  <div>
    <b-card title="Producción anual" class="shadow-none">
      <b-card-text>
        <b-row align-v="start" class="my-0">
          <b-col lg="6">
            <span class="font-weight-bold text-success">Fecha de inicio</span>
            <span class="user-status font-weight-light text-success">01/03/2022</span>
          </b-col>
          <b-col lg="6">
            <span class="font-weight-bold text-success">Fecha de finalización</span>
            <span class="user-status font-weight-light text-success">31/12/2022</span>
          </b-col>
        </b-row>
      </b-card-text>
      <b-card-text>
        <b-row class="align-items-center justify-content-center mt-2">
          <items :cantidad="1800" :vivero="'San Emigdio'"></items>
          <items :cantidad="3000" :vivero="'Las guacas'"></items>
          <items :cantidad="2500" :vivero="'Vivero 3'"></items>
          <items :cantidad="2500" :vivero="'Vivero 4'"></items>
          <items :cantidad="2500" :vivero="'Vivero 5'"></items>
        </b-row>
      </b-card-text>
    </b-card>
    <b-card title="Producción actual" class="shadow-none mt-0">
      <b-card-text>
        <div class="w-100 p-3 shadow rounded-bt">
          <h1 class="text-center font-weight-bold">
            38.000
          </h1>
          <p class="text-center text-muted h3 mb-0">
            Productos
          </p>
        </div>
      </b-card-text>
    </b-card>

    <b-card title="Producción actual" class="mt-0">
      <b-card-text>
        <b-table :items="items" :fields="fields" table-variant="light"></b-table>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import Items from './components/Items.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    Items
  },
  data() {
    return {
      logo: require('@/assets/images/logo/Arbol.png'),
      fields: [
        {
          key: 'mes',
          label: 'Mes',
        },
        {
          key: 'san_emigdio',
          label: 'San Emigdio',
        },
        {
          key: 'guacas',
          label: 'Las Guacas',
        },
        {
          key: 'vivero_3',
          label: 'Vivero 3',
        },
        {
          key: 'vivero_4',
          label: 'Vivero 4',
        },
        {
          key: 'vivero_5',
          label: 'Vivero 5',
        },
        {
          key: 'total',
          label: 'Total Mensual',
        },
      ],
      items: [
        { mes: 'Enero', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Febrero', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Marzo', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Abril', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Mayo', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Junio', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Julio', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Agosto', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Septiembre', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Octubre', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Noviembre', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },
        { mes: 'Diciembre', san_emigdio: '103', guacas: '103', vivero_3: '103', vivero_4: '103', vivero_5: '103', total: '1000' },

      ],
    }
  }
}
</script>
<style>
.rounded-bt {
  border-bottom-left-radius: 1.7rem !important;
  border-bottom-right-radius: 1.7rem !important;
}
</style>
