<template>
    <b-col md="2">
        <div class="shadow rounded-bt p-1">
            <h4 class="text-center mb-0">
                {{ cantidad }}
            </h4>
            <p class="text-center text-muted">
                Productos
            </p>
            <b-img :src="logo" class="mx-auto d-block w-25 mt-2" />
            <h5 class="text-center">
                {{ vivero }}
            </h5>
        </div>
    </b-col>
</template>
<script>
export default {
    props: {
        cantidad: Number,
        vivero: String,

    },
    data() {
        return {
            logo: require('@/assets/images/logo/Arbol.png')
        }
    }
}
</script>